



























import gql from 'graphql-tag'
import { Component, Vue, Prop } from 'vue-property-decorator'
import moment from 'moment'

import Header from '@/components/Header.vue'
import LoadingDots from '@/components/LoadingDots.vue'
import ContainerCard from '@/components/ContainerCard.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'

import Companies from '@/graphql/queries/Companies.gql'
import {
  ICompaniesData,
  ICompany
} from '@/graphql/queries/interfaces/Companies.interface'
import CompanyActivate from '@/graphql/companyService/CompanyActivate.gql'
import CompanyDeactivate from '@/graphql/companyService/CompanyDeactivate.gql'
import { CompanyOrder } from '@/graphql/queries/interfaces/CompanyOrder.enum'
import { OverlayController } from '../lib/utils/OverlayController'

@Component<CompaniesView>({
  components: {
    Header,
    ContainerCard,
    LoadingDots
  },
  // @ts-ignore
  head: {
    title () {
      return {
        inner: `${this.$t('companies.hint')}`
      }
    }
  }
})
export default class CompaniesView extends Vue {
  companies: ICompany[] = []
  loadingCompanies: string[] = []
  processingBlockingCompanies: Object = {};

  mounted() {
    this.getCompanies()
  }

  isLoading(company: ICompany) {
    return this.loadingCompanies.includes(company.id)
  }

  async toggleBlock(company: ICompany) {
    // @ts-ignore
    if (this.processingBlockingCompanies[company.id] === true) {
      return;
    }
    // @ts-ignore
    this.processingBlockingCompanies[company.id] = true;
    // @ts-ignore
    this.processingBlockingCompanies = { ...this.processingBlockingCompanies };
    const newIsBlocked = !company.isBlocked
    const status = newIsBlocked ? "BLOCKED" : "ACTIVE";
    try {
      await this.$apollo.mutate({
          mutation: gql`mutation SetCompanyBlockedStatus($id: ID!, $status: CompanyStatus!) {
            setCompanyBlockedStatus(compId: $id, status: $status)
          }`,
          variables: {
            id: company.id,
            status,
        },
      });
      company.isBlocked = newIsBlocked;
      this.$toast.open({ message: `${this.$t('toast.success.generic')}` })
    } catch (err) {
      this.$toast.open({ type: 'error', message: `${this.$t('toast.Something went wrong')}` })
    }
    // @ts-ignore
    delete this.processingBlockingCompanies[company.id];
    // @ts-ignore
    this.processingBlockingCompanies = { ...this.processingBlockingCompanies };
  }

  // @ts-ignore
  showPaymentInfo({ paymentInfo }) {
  	paymentInfo.__typename = undefined
	alert(JSON.stringify(paymentInfo, null, 3));
  }

  async getCompanies() {
    const { data } = await this.$apollo.query<ICompaniesData>({
      query: Companies,
      fetchPolicy: 'no-cache',
      variables: {
        order: CompanyOrder.createdAt_ASC
      }
    })
    this.companies = data.companies.edges.map(edge => edge.node)
    for (const company of this.companies) {
      company.createdAt = moment(company.createdAt).format('DD.MM.YYYY')
    }
    this.$forceUpdate()
  }

  async activateCompany(company: ICompany) {
    const confirmComponent = await OverlayController.loadComponent(
      ConfirmModal,
      {
        props: {
          text: this.$t('companies.confirmActivate', { company: company.name })
        }
      }
    )
    confirmComponent.onConfirm = async () => {
      const loadingIndex = this.loadingCompanies.push(company.id) - 1
      const { data } = await this.$apollo.mutate({
        mutation: CompanyActivate,
        variables: { id: company.objectId }
      })
      await this.getCompanies()
      this.loadingCompanies.splice(loadingIndex)
    }
  }

  async deactivateCompany(company: ICompany) {
    const confirmComponent = await OverlayController.loadComponent(
      ConfirmModal,
      {
        props: {
          text: this.$t('companies.confirmDeactivate', { company: company.name })
        }
      }
    )
    confirmComponent.onConfirm = async () => {
      const loadingIndex = this.loadingCompanies.push(company.id) - 1
      const { data } = await this.$apollo.mutate({
        mutation: CompanyDeactivate,
        variables: { id: company.objectId }
      })
      await this.getCompanies()
      this.loadingCompanies.splice(loadingIndex)
    }
  }

  adressOf(company: any) {
    return `${company.street}, ${company.zip} ${company.city}`
  }
}
